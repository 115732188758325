import React from "react"
import {Link as LinkBase} from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import DbaasProviders from "./_dbaasProviders";
import StorageProviders from "../storage-backup/_storageProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="aws-rds"
      title="Automated AWS RDS Backup Service"
      description="Automate your RDS backups. Automated, stored on any cloud provider, and fully controlled by you. ISO-27011 certified."
    />
    <Layout.Hero
      type="primary"
      title={(<><span className="text-primary">AWS RDS</span> backups<br/> on you own terms.</>)}
      subTitle={(
        <>Create automatic database backups for your RDS database.<br/>
          Select your schedule, your storage and get full control of your backups.
        </>)}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your RDS backup" urlAtrr={{ sb_source: "website", sb_term: "awsrds-hero" }} />)}
      illustration={(
          <Ui.Image className="" filename="simplebackups-aws-rds-hero.png" alt="Database as a service backup solution"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <div className="md:mb-24 ">
        <Sections.JoinBrandsSection />
      </div>
    </Ui.Container>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              Content_FeatureControl({subject: 'AWS RDS'}),
              Content_FeatureTrust(),

            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">The future is multi-cloud, so are your backups</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">
              Meet the backup solution built for the AWS RDS's users who're looking for higher backup standards.</p>


            <div className="grid md:grid-cols-3 md:gap-8 gap-8 text-left my-20">
              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-1 text-md mr-3 text-primary"></i>Backups Made Simple</h3>
                <p className="text-base">Eliminate the need to navigate the complexities of AWS backup systems and their unexpected expenses.</p>
              </div>

              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-2 text-md mr-3 text-primary"></i>Multi-cloud backups</h3>
                <p className="text-base">Stop trusting your backups to live where your data is hosted.<br/>Control where your backups are stored and restore them where you want.</p>
              </div>

              <div className="bg-white rounded-xl p-8 shadow-xl">
                <h3 className="text-xl font-bold mb-3"><i className="fa fa-circle-3 text-md mr-3 text-primary"></i>Restore on any IaaS</h3>
                <p className="text-base">Liberate your backups from being confined to a single provider.<br/>Experience the freedom to restore your data on any platform, ensuring flexibility and adaptability.</p>
              </div>
            </div>


            <p className="py-5 text-lg font-bold">Everything you need for RDS backups in one friendly, easy-to-use service:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup all databases at once</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Select/Exclude tables</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Advanced database flags</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">No-infrastructure required (serveless)</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Support for (very) large database backups</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Downloadable backup archives</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Automated restore</span>
              </li>
            </ul>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-20 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["mysql"]} />

            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about RDS Backups?</>}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_is_multicloud', 'can_i_restore_other_provider', 'can_download_backup']}/>


  </Layout.Layout>
)

export default ContentPage
